<script setup lang="ts">
import { useElementBounding } from "@vueuse/core";
import BaseHeaderAuthButtons from "./BaseHeaderAuthButtons.vue";
import BaseHeaderBurgerMenu from "./BaseHeaderBurgerMenu.vue";

export interface HeaderUser {
  fullName: string;
}

export interface BaseHeaderProps {
  /**
   * An array of the header links that placed next to the logo.
   */
  links?: IHeaderLink[];
  /**
   * Set transparent background for the header.
   */
  transparent?: boolean;
  /**
   * Sets header with fixed style.
   */
  fixed?: boolean;
  /**
   * Sets header with absolute style.
   */
  absolute?: boolean;
  /**
   * Disables the header auth buttons.
   */
  disabledAuth?: boolean;
  /**
   * URL to the header logo. Height is fixed to 56px(full header height), width auto adjusted.
   */
  logoImageUrl?: string;
  /**
   * Shows search input.
   */
  withSearch?: boolean;
  /**
   *  The current value of the search input.
   */
  searchValue?: string;
  /**
   * Shows "Sign In" and "Sign Up" buttons.
   */
  withAuth?: boolean;
  /**
   * Defines provider which will be used for authentication.
   *  If it defines, user doesn't redirected to the auth page. Instead, the provider's popup will be opened.
   */
  provider?: TAuthProvider;
  /**
   * Shows "Sign Out" / "Logout" buttons.
   *
   * Note: this is incompatible with the 'withAuth' prop.
   */
  withLocale?: boolean;
  /**
   * Shows dropdown button with locale list.
   */
  withUser?: null | HeaderUser;
  /**
   * Shows mobile menu button.
   */
  withMobileMenu?: boolean;
  /**
   * Shows theme switcher button.
   */
  withThemeSwitcher?: boolean;
  /**
   * Shows notifications button.
   */
  withNotifications?: boolean;
  /**
   * The default locale.
   */
  defaultLocale?: ILocale;
  /**
   * The list of available locales.
   */
  availableLocales: ILocale[];
  /**
   * The list of the most common sport types.
   */
  mostCommonSportTypesList?: TSportType[];
  /**
   * The redirect URL.
   */
  redirectUrl?: string;
}

const { t } = useI18n();
const emit = defineEmits(["logout", "loginProvider", "search", "clear"]);
const props = withDefaults(defineProps<BaseHeaderProps>(), {
  logoImageUrl: "/images/mock/logo.svg",
  withThemeSwitcher: true,
});

const searchValue = ref(props.searchValue);
const rightSectionRef = ref<HTMLElement | null>(null);
const leftSectionRef = ref<HTMLElement | null>(null);
const { width: rightSectionWidth } = useElementBounding(rightSectionRef);
const { width: leftSectionWidth } = useElementBounding(leftSectionRef);

const filteredLinks = computed(() => props.links?.filter(link => link.withUserOnly ? Boolean(props.withUser) : true));
const onInputSubmit = () => emit("search", searchValue.value);
const onClickLoginWithProvider = (provider: TAuthProvider) => emit("loginProvider", provider);
const clearSearch = () => (searchValue.value = "");
const isVisibleDropdownLocale = computed(() => props.availableLocales.length > 1 && props.withLocale);

watch(() => props.searchValue, q => (searchValue.value = q));
</script>

<template>
  <header class="baseHeader" :class="{ baseHeader_fixed: props.fixed, baseHeader_absolute: props.absolute, baseHeader_default: !props.transparent }">
    <div ref="leftSectionRef">
      <slot name="logo">
        <div class="min-w-[56px]">
          <BaseButton type="icon" tag="nuxt-link" to="/" name="logo-link" link>
            <BaseImage class="baseHeader__logo" :src="props.logoImageUrl" fallback-src="/images/mock/logo.svg" alt="Logo" width="auto" :height="56" />
          </BaseButton>
        </div>
      </slot>
    </div>
    <slot name="left" />
    <div class="flex-1">
      <div class="flex items-center justify-center w-full">
        <slot name="append-center" />
      </div>
      <slot name="links">
        <BaseHeaderLinks v-if="filteredLinks?.length" :links="filteredLinks" :right-offset="rightSectionWidth" :left-offset="leftSectionWidth" />
      </slot>
    </div>

    <div ref="rightSectionRef" class="flex items-center justify-center gap-4 rtl:pr-6 ltr:pl-6">
      <slot name="right">
        <template v-if="props.withSearch">
          <BaseSearchInput
            v-model="searchValue"
            :most-common-sport-types-list="props.mostCommonSportTypesList"
            :placeholder="t('labels.search_event_league_team')"
            class="hidden lg:block w-[22rem]"
            root-class="!text-sm truncate"
            @keyup.enter="onInputSubmit()"
            @clear="clearSearch()"
          />
          <BaseButton class="flex items-center justify-center lg:hidden" type="icon" name="search" title="Search" @click="onInputSubmit()">
            <IconSearch class="mx-2 text-main" />
          </BaseButton>
        </template>

        <UserMenuSelectionLanguage
          v-if="isVisibleDropdownLocale"
          class="hidden lg:flex"
          :available-locales="props.availableLocales"
        />

        <BaseButton v-if="props.withNotifications" name="notifications" title="Notifications" type="icon">
          <IconBell />
        </BaseButton>

        <UserMenu
          v-if="props.withUser?.fullName"
          :full-name="props.withUser.fullName"
          :with-theme-switcher="props.withThemeSwitcher"
          class="hidden lg:flex"
          @logout="emit('logout')"
        />

        <BaseHeaderAuthButtons v-if="props.withAuth" class="hidden lg:flex" :redirect-url="props.redirectUrl">
          <template v-if="props.provider">
            <div class="flex rtl:pr-6 ltr:pl-6">
              <BaseButton
                :name="`sign-in-${props.provider}`"
                class="w-full text-button-bold lg:w-auto lg:flex-shrink-0"
                :title="t('labels.sign_in')"
                :disabled="props.disabledAuth"
                @click="onClickLoginWithProvider(props.provider)"
              >
                {{ t('labels.sign_in') }}
              </BaseButton>
            </div>
          </template>
        </BaseHeaderAuthButtons>

        <BaseHeaderBurgerMenu
          v-if="props.withMobileMenu"
          :links="filteredLinks"
          :with-auth="props.withAuth"
          :with-user="props.withUser"
          :with-theme-switcher="props.withThemeSwitcher"
          @logout="emit('logout')"
        >
          <template v-if="props.provider" #auth-buttons>
            <div class="flex px-4 text-center md:px-24 max-lg:py-10 lg:flex-row max-lg:mb-24">
              <BaseButton
                :name="`sign-in-${props.provider}`"
                class="w-full text-button-bold lg:w-auto lg:flex-shrink-0"
                :title="t('labels.sign_in')"
                :disabled="props.disabledAuth"
                @click="onClickLoginWithProvider(props.provider)"
              >
                {{ t('labels.sign_in') }}
              </BaseButton>
            </div>
          </template>
        </BaseHeaderBurgerMenu>
      </slot>

      <slot name="append-end" />
    </div>
  </header>
</template>

<style lang="scss">
.baseHeader {
  @apply w-full md:h-14 px-4 md:px-10 flex items-center justify-start z-[100] relative transition-all;
  border-bottom: 1px solid transparent;

  &.baseHeader_absolute {
    @apply absolute top-0 left-0;
  }

  &.baseHeader_fixed {
    @apply fixed top-0 left-0;
  }

  &.baseHeader_default {
    @apply py-4 md:py-[3.25rem];
    background-color: var(--base-background-color);
    border-bottom: 1px solid var(--base-header-line-color);
  }

  .baseHeader__links {
    @apply hidden lg:flex items-center overflow-hidden;

    .baseHeader__link {
      @apply mx-6 font-medium flex-shrink-0 line-clamp-2 max-w-[9rem] text-center hover:text-[var(--base-text-color)];

      text-decoration-line: none;
    }
  }

  .router-link-active {
    color: var(--base-text-color) !important;
  }

  .headerDropdown .headerDropdown__menu,
  .basePopover__content_bottom-right {
    @apply rtl:-right-[245px]; // TODO: Fix this in popover.
  }
}

.baseHeader__logo {
  @apply max-h-14 w-auto;
}
</style>
